import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faSearchLocation, faUsers, faCalendarCheck  } from '@fortawesome/free-solid-svg-icons';
import '../fonts/fonts.css';
import logoImage from '../images/logoWhite.png';
import one from '../images/1.png'; // Ensure the path is correct
import two from '../images/2.jpg';

const images = [one, two]; // Array of images
const randomImage = images[Math.floor(Math.random() * images.length)];

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #ffffff;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: transparent; /* Remove background */
`;


const Logo = styled.img`
  height: 90px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  top: -15px;
`;

const NavLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    background: #f0f0f0;
    color: #0984e3;
    transform: translateY(-2px);
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px; /* Gap between buttons */
  margin-top: 20px; /* Adds some space above */
`;

const ActionButton = styled.button`
  background-color: #0984e3;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s, transform 0.3s;
  &:hover {
    background-color: #0670C9;
    transform: scale(1.08);
    box-shadow: 0px 6px 15px rgba(9, 132, 227, 0.35);
  }
`;

const SecondaryButton = styled.button`
  background-color: white;
  color: #0984e3;
  border: 2px solid #0984e3;
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s, transform 0.3s;
  
  &:hover {
    background-color: #f0f0f0;
    transform: scale(1.08);
  }
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  z-index: 1; /* Ensure content is above the overlay */
`;

const HeroContainer = styled.div`
position: relative;
width: 100%;
height: 100vh;
background-image: url(${randomImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;

&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Adjust opacity here (0.4 = 40%) */
  z-index: 0;
}
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 0 20px;
  animation: ${fadeInUp} 1s ease-out;
`;

const HeroHeading = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubheading = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
  margin: 0;
  margin-bottom: 32px;
  align-items: center;
`;

const HowItWorksSection = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #2d3436;
  font-weight: 700;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
  padding: 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
`;

const StepCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #0984e3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;

  ${StepContainer}:hover & {
    background-color: #74b9ff;
  }
`;

const StepIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 12px;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #636e72;
  line-height: 1.6;
`;


const Footer = styled.footer`
  background: #fff;
  padding: 60px 20px;
  color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #eaeaea;
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0984e3;
  color: white;
  border-radius: 50%;
  border: none;
  width: 48px;
  height: 48px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
  &:hover {
    background-color: #74b9ff;
    transform: scale(1.1);
  }
`;

const Locals = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);

  const steps = [
    {
      id: 1,
      icon: faSearchLocation,
      title: "Choose Your Destination",
      description: "Search for your dream location using our intelligent search with real-time suggestions. Get detailed information about local attractions and cultural highlights."
    },
    {
      id: 2,
      icon: faUsers,
      title: "Connect with Local Guides",
      description: "Browse verified local experts who create personalized itineraries. Chat directly with guides to tailor your perfect experience."
    },
    {
      id: 3,
      icon: faCalendarCheck,
      title: "Book & Experience",
      description: "Secure instant booking with our protected payment system. Receive real-time updates and embark on your authentic local adventure!"
    }
  ];

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <PageContainer>
      <HeroContainer style={{ backgroundImage: `url(${randomImage})` }}>
        <HeaderContainer>
          <Logo src={logoImage} alt="Travel Local Logo" onClick={() => navigate('/')} />
          <NavLinks>
            <NavLink href="#features">Features</NavLink>
            <NavLink href="#about">How it works</NavLink>
            <NavLink href="/">Travelers</NavLink>
            <NavLink href="login">Sign in</NavLink>
            <ActionButton onClick={() => navigate('/signup')}>Get Started</ActionButton>
          </NavLinks>
      </HeaderContainer>

      <HeroSection>
      <HeroContent>
  <HeroHeading>Connect with Travelers, Share Local Experiences</HeroHeading>
  <HeroSubheading>
    Connect with travelers and guide them through your city’s hidden gems, local favorites, and unforgettable experiences.
  </HeroSubheading>
  <ButtonContainer>
    <ActionButton onClick={() => navigate('/signup')}>Get Started</ActionButton>
    <SecondaryButton onClick={() => navigate('/learn-more')}>Learn More</SecondaryButton>
  </ButtonContainer>
</HeroContent>
        </HeroSection>
      </HeroContainer>

      <HowItWorksSection id="how-it-works">
      <SectionTitle>How It Works</SectionTitle>
      <StepsContainer>
        {steps.map((step) => (
          <StepContainer key={step.id}>
            <StepCircle>
              <StepIcon icon={step.icon} />
            </StepCircle>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </StepContainer>
        ))}
      </StepsContainer>
    </HowItWorksSection>

      <Footer>
        <BackToTopButton className={showButton ? 'visible' : ''} onClick={handleBackToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </BackToTopButton>
      </Footer>
    </PageContainer>
  );
};

export default Locals;