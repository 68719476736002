import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faPaperPlane, faSearchLocation, faUsers, faCalendarCheck } from '@fortawesome/free-solid-svg-icons';
import '../fonts/fonts.css';
import logoImage from '../images/logoWhite.png';
import one from '../images/1.png'; // Ensure the path is correct
import two from '../images/2.jpg';
import three from '../images/3.png';
import four from '../images/4.png';
import five from '../images/5.jpg';
import six from '../images/6.png';
import seven from '../images/7.jpg';
import eight from '../images/8.png';
import nine from '../images/9.webp';
import ten from '../images/10.jpg';
import axios from "axios";

const MAPBOX_API_KEY = "pk.eyJ1IjoibmltYWktZ2FyZyIsImEiOiJjbTdjb2ZqNXAwcjduMnJwcGNkMXQ4bHVmIn0.r2Xii2wJGb79WREWNQKKDA"; // Replace with your key
const MAPBOX_API_URL = "https://api.mapbox.com/geocoding/v5/mapbox.places/";

const images = [one, two, three, four, five, six, seven, eight, nine, ten]; // Array of images
const randomImage = images[Math.floor(Math.random() * images.length)];

const fadeInUp = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: #ffffff;
  overflow-x: hidden;
  font-family: 'Inter', sans-serif;
`;

const HeaderContainer = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: transparent; /* Remove background */
`;


const Logo = styled.img`
  height: 90px;
  cursor: pointer;
  transition: transform 0.3s;
  &:hover {
    transform: scale(1.05);
  }
`;

const NavLinks = styled.nav`
  display: flex;
  gap: 16px;
  align-items: center;
  position: relative;
  top: -15px;
`;

const NavLink = styled.a`
  text-decoration: none;
  font-weight: 500;
  font-size: 1rem;
  color: #fff;
  padding: 8px 16px;
  border-radius: 8px;
  transition: all 0.3s;
  &:hover {
    background: #f0f0f0;
    color: #0984e3;
    transform: translateY(-2px);
  }
`;

const ActionButton = styled.button`
  background-color: #0984e3;
  color: #fff;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 30px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s, transform 0.3s;
  &:hover {
    background-color: #0670C9;
    transform: scale(1.08);
    box-shadow: 0px 6px 15px rgba(9, 132, 227, 0.35);
  }
`;

const HeroSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: white;
  overflow: hidden;
  z-index: 1; /* Ensure content is above the overlay */
`;

const HeroContainer = styled.div`
position: relative;
width: 100%;
height: 100vh;
background-image: url(${randomImage});
background-size: cover;
background-position: center;
background-repeat: no-repeat;

&::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); /* Adjust opacity here (0.4 = 40%) */
  z-index: 0;
}
`;

const HeroContent = styled.div`
  max-width: 800px;
  padding: 0 20px;
  animation: ${fadeInUp} 1s ease-out;
`;

const HeroHeading = styled.h1`
  font-size: 3.5rem;
  font-weight: 600;
  color: white;
  margin: 0;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const HeroSubheading = styled.p`
  font-weight: 500;
  font-size: 1.5rem;
  color: white;
  margin: 0;
  margin-bottom: 32px;
  align-items: center;
`;

const InputWrapperContainer = styled.div`
  display: flex;
  justify-content: center; /* Center the input and button */
  align-items: center; /* Align them vertically */
  gap: 12px; /* Add spacing between input and button */
  width: 100%;
  max-width: 500px; /* Control max width */
  margin: 0 auto; /* Center the container */
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 30px;
  padding: 12px 18px;
  flex-grow: 1;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Soft shadow */
  border: 1.5px solid #dfe6e9;
  transition: 0.3s ease-in-out;
  position: relative; /* Added to position the suggestions */
`;

const Input = styled.input`
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 500;
  width: 100%;
  color: #2d3436;
  background: transparent;
  padding: 8px 10px;

  &::placeholder {
    color: rgb(143, 152, 156);
    font-weight: 400;
  }
`;

const SearchButton = styled.button`
  background: #0984e3;
  color: white;
  border: none;
  padding: 14px 24px; /* Increased padding */
  border-radius: 40px; /* More rounded */
  cursor: pointer;
  font-size: 16px; /* Slightly larger font */
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  transition: all 0.3s ease-in-out;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.12); /* Slightly deeper shadow */
  border: 2px solid transparent;

  &:hover {
    background-color: #74b9ff;
    transform: scale(1.08);
    box-shadow: 0px 6px 15px rgba(9, 132, 227, 0.35);
  }

  &:active {
    transform: scale(0.96);
  }
`;

const SuggestionsList = styled.ul`
  position: absolute;
  width: 100%;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  list-style: none;
  margin: 0;
  padding: 0;
  max-height: 130px; /* Smaller size */
  overflow-y: auto;
  top: 110%; /* Move suggestions list below the input */
  left: 0;
  z-index: 1; /* Ensure suggestions are above the input field */
`;

const SuggestionItem = styled.li`
  padding: 10px;
  cursor: pointer;
  color: black;
  &:hover {
    background: #f0f0f0;
  }
`;

const HowItWorksSection = styled.section`
  padding: 80px 20px;
  background-color: #f9f9f9;
`;

const SectionTitle = styled.h2`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 60px;
  color: #2d3436;
  font-weight: 700;
`;

const StepsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
  max-width: 1200px;
  margin: 0 auto;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`;

const StepContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  max-width: 300px;
  padding: 20px;
  background: white;
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 30px rgba(0, 0, 0, 0.15);
  }
`;

const StepCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: #0984e3;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 20px;
  transition: background-color 0.3s ease;

  ${StepContainer}:hover & {
    background-color: #74b9ff;
  }
`;

const StepIcon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const StepTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 600;
  color: #2d3436;
  margin-bottom: 12px;
`;

const StepDescription = styled.p`
  font-size: 1rem;
  color: #636e72;
  line-height: 1.6;
`;


const Footer = styled.footer`
  background: #fff;
  padding: 60px 20px;
  color: #444;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-top: 1px solid #eaeaea;
`;

const BackToTopButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #0984e3;
  color: white;
  border-radius: 50%;
  border: none;
  width: 48px;
  height: 48px;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s, transform 0.3s;
  &.visible {
    opacity: 1;
    pointer-events: auto;
  }
  &:hover {
    background-color: #74b9ff;
    transform: scale(1.1);
  }
`;

const Landing = () => {
  const navigate = useNavigate();
  const [showButton, setShowButton] = useState(false);
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [validLocation, setValidLocation] = useState(false);

  const steps = [
    {
      id: 1,
      icon: faSearchLocation,
      title: "Choose Your Destination",
      description: "Search for your dream location using our intelligent search with real-time suggestions. Get detailed information about local attractions and cultural highlights."
    },
    {
      id: 2,
      icon: faUsers,
      title: "Connect with Local Guides",
      description: "Browse verified local experts who create personalized itineraries. Chat directly with guides to tailor your perfect experience."
    },
    {
      id: 3,
      icon: faCalendarCheck,
      title: "Book & Experience",
      description: "Secure instant booking with our protected payment system. Receive real-time updates and embark on your authentic local adventure!"
    }
  ];

  const fetchSuggestions = async (input) => {
    if (input.length < 2) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await axios.get(
        `${MAPBOX_API_URL}${encodeURIComponent(input)}.json`,
        {
          params: {
            access_token: MAPBOX_API_KEY,
            types: "place,country", // Only fetch cities and countries
            limit: 5, // Limit results
            autocomplete: true,
          },
        }
      );

      setSuggestions(response.data.features);
    } catch (error) {
      console.error("Error fetching locations:", error);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setQuery(value);
    setValidLocation(false); // Reset the selection status
    fetchSuggestions(value);
  };

  const handleSelect = (place) => {
    setQuery(place.place_name);
    setSuggestions([]);
    setValidLocation(true); // Mark the location as valid once selected
  };

  const handleBackToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    const handleScroll = () => {
      setShowButton(window.scrollY > 300);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleVisit = () => {
    if (query === "" || !validLocation) {
      alert("Please select a location from the list.");
      return;
    }
    navigate('/visit');
  };

  return (
    <PageContainer>
      <HeroContainer style={{ backgroundImage: `url(${randomImage})` }}>
        <HeaderContainer>
          <Logo src={logoImage} alt="Travel Local Logo" onClick={() => navigate('/')} />
          <NavLinks>
            <NavLink href="#features">Features</NavLink>
            <NavLink href="#about">How it works</NavLink>
            <NavLink href="pricing">Pricing</NavLink>
            <NavLink href="locals">Locals</NavLink>
            <NavLink href="login">Sign in</NavLink>
            <ActionButton onClick={() => navigate('/signup')}>Get Started</ActionButton>
          </NavLinks>
      </HeaderContainer>

      <HeroSection>
        <HeroContent>
          <HeroHeading>Travel Like a Local, Connect with Ease</HeroHeading>
          <HeroSubheading>
          Discover authentic experiences and insider tips from locals around the world. Immerse yourself in culture, food, and adventure.
          </HeroSubheading>
          <InputWrapperContainer>
          <InputWrapper>
            <Input
              type="text"
              value={query}
              onChange={handleChange}
              placeholder="Where do you want to go?"
            />
            {suggestions.length > 0 && (
              <SuggestionsList>
                {suggestions.map((place) => (
                  <SuggestionItem key={place.id} onClick={() => handleSelect(place)}>
                    {place.place_name}
                  </SuggestionItem>
                ))}
              </SuggestionsList>
            )}
          </InputWrapper>

            <SearchButton
              onClick={handleVisit}
              disabled={query === "" || !validLocation} // Disable button if no valid location or input is empty
              style={{
                backgroundColor: query === "" || !validLocation ? '#b2bec3' : '#0984e3', // Change color when inactive
                cursor: query === "" || !validLocation ? 'not-allowed' : 'pointer',
              }}
            >
              <FontAwesomeIcon icon={faPaperPlane} />
            </SearchButton>
</InputWrapperContainer>
          </HeroContent>
        </HeroSection>
      </HeroContainer>

      <HowItWorksSection id="how-it-works">
      <SectionTitle>How It Works</SectionTitle>
      <StepsContainer>
        {steps.map((step) => (
          <StepContainer key={step.id}>
            <StepCircle>
              <StepIcon icon={step.icon} />
            </StepCircle>
            <StepTitle>{step.title}</StepTitle>
            <StepDescription>{step.description}</StepDescription>
          </StepContainer>
        ))}
      </StepsContainer>
    </HowItWorksSection>

      <Footer>
        <BackToTopButton className={showButton ? 'visible' : ''} onClick={handleBackToTop}>
          <FontAwesomeIcon icon={faArrowUp} />
        </BackToTopButton>
      </Footer>
    </PageContainer>
  );
};

export default Landing;