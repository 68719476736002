import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const Container = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 40px 20px;
  color: #333;
`;

const Header = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  font-family: 'Inter', sans-serif;
  text-align: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const EffectiveDate = styled.p`
  font-size: 14px;
  color: #666;
  margin-right: auto;
  font-family: 'Inter', sans-serif;
  font-style: italic;
  font-weight: 700;
`;

const Divider = styled.hr`
  flex-grow: 1;
  border: none;
  height: 1px;
  background-color: #ccc;
  margin-left: 20px;
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  font-family: 'Inter', sans-serif;
  margin-top: 40px;
  margin-bottom: 10px;
  color: #444;
`;

const Paragraph = styled.p`
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const BoldParagraph = styled(Paragraph)`
  font-family: 'Inter', sans-serif;
`;

const Link = styled.a`
  color: #d87c52;
  text-decoration: none;
  font-family: 'Inter', sans-serif;

  &:hover {
    font-family: 'Inter', sans-serif;
  }
`;

const UnlistedList = styled.ul``;

const ListItem = styled.li`
  font-family: 'Inter', sans-serif;
`;

const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  color: #333;
  padding: 12px 24px;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-family: 'Inter', sans-serif;
  font-size: 1rem;
  font-weight: bold;
  position: absolute;
  left: 40px;
  top: 20px;

  &:hover,
  &:focus {
    background-color: #e0e0e0;
    transform: translateX(-5px);
  }

  svg {
    margin-right: 8px;
  }
`;

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <Container>
      <GoBackContainer onClick={handleGoBack} tabIndex="0">
        <FaArrowLeft />
        Go Back
      </GoBackContainer>
      <Header>Privacy Policy</Header>
      <HeaderContainer>
        <EffectiveDate>Effective Date: January 4, 2025</EffectiveDate>
        <Divider />
      </HeaderContainer>

      <BoldParagraph>
        Welcome to TravelLocal (“we,” “us,” or “our”). This Privacy Policy explains how we collect, use, share, and protect your information when you use the TravelLocal application, website, and services (“Services”). By using our Services, you agree to this Privacy Policy.
      </BoldParagraph>

      <SectionTitle>1. Information We Collect</SectionTitle>
      <Paragraph>We collect two types of information:</Paragraph>
      <Paragraph>a. Personal Information</Paragraph>
      <Paragraph>This includes information you provide when you sign up, such as:</Paragraph>
      <UnlistedList>
        <ListItem>Name</ListItem>
        <ListItem>Email Address</ListItem>
        <ListItem>Account Type (Google or Email/Password)</ListItem>
        <ListItem>Setup Preferences</ListItem>
        <ListItem>Profile Information (e.g., profile photo, optional details like interests)</ListItem>
        <ListItem>Other Information (e.g., optional feedback, reviews)</ListItem>
      </UnlistedList>

      <Paragraph>b. Non-Personal Information</Paragraph>
      <Paragraph>This includes data collected automatically:</Paragraph>
      <UnlistedList>
        <ListItem>Device Information (e.g., IP address, browser type, operating system)</ListItem>
        <ListItem>Usage Data (e.g., features used, pages visited, interactions within the app)</ListItem>
        <ListItem>Location Data (if you enable location services)</ListItem>
        <ListItem>Analytics Data (collected via tools like Google Analytics and Firebase)</ListItem>
      </UnlistedList>

      <SectionTitle>2. How We Use Your Information</SectionTitle>
      <Paragraph>We use your data to:</Paragraph>
      <UnlistedList>
        <ListItem>Provide and Enhance Services - Ensure smooth operation of our app and improve your experience.</ListItem>
        <ListItem>Personalize Your Experience - Offer customized features like travel recommendations or local guides.</ListItem>
        <ListItem>Communicate - Respond to your inquiries, send important updates, and deliver customer support.</ListItem>
        <ListItem>Legal Compliance - Comply with applicable laws and regulations.</ListItem>
        <ListItem>Safety and Fraud Prevention - Detect and prevent unauthorized access or harmful activities.</ListItem>
      </UnlistedList>

      <SectionTitle>3. How We Share Your Information</SectionTitle>
      <Paragraph>We will not sell or rent your personal information to third parties. We may share your data with:</Paragraph>
      <UnlistedList>
        <ListItem>Service Providers - Trusted vendors who help us operate the Services (e.g., hosting providers, email platforms).</ListItem>
        <ListItem>Legal Authorities - Government authorities or legal entities if required by law or to protect our legal rights.</ListItem>
        <ListItem>TravelLocal Users - Limited data (e.g., your profile photo, name, and basic details) may be visible to other users if you choose to engage with features like meet-ups or shared travel itineraries.</ListItem>
      </UnlistedList>

      <SectionTitle>4. Data Retention</SectionTitle>
      <Paragraph>
        We retain your data as long as your account is active or necessary for the purposes outlined in this Privacy Policy. You may request the deletion of your data by contacting us.
      </Paragraph>

      <SectionTitle>5. Your Data Rights</SectionTitle>
      <Paragraph>You have the right to:</Paragraph>
      <UnlistedList>
        <ListItem>Access and Update Your Data - View or modify your personal information via account settings.</ListItem>
        <ListItem>Request Deletion - Delete your account and associated data upon request.</ListItem>
        <ListItem>Withdraw Consent - Disable certain features (e.g., location tracking) in app settings.</ListItem>
        <ListItem>Opt-Out - Unsubscribe from marketing emails by following the instructions provided in the emails.</ListItem>
        <ListItem>Request a Copy - Request an export of your personal data.</ListItem>
      </UnlistedList>

      <SectionTitle>6. Cookies and Tracking Technologies</SectionTitle>
      <Paragraph>We use cookies and similar technologies to:</Paragraph>
      <UnlistedList>
        <ListItem>Enhance your experience by remembering preferences and login details.</ListItem>
        <ListItem>Monitor and analyze traffic for performance improvements.</ListItem>
      </UnlistedList>

      <SectionTitle>7. Security</SectionTitle>
      <Paragraph>We implement measures to safeguard your information, including:</Paragraph>
      <UnlistedList>
        <ListItem>Encryption - Encrypting personal data during transmission and storage.</ListItem>
        <ListItem>Access Controls - Limiting access to your data to authorized personnel.</ListItem>
      </UnlistedList>

      <SectionTitle>8. Children’s Privacy</SectionTitle>
      <Paragraph>
        Our Services are not intended for children under the age of 16. By accessing our Services, you confirm that you are at least 16 years old or have proper parental or legal guardian consent. We disclaim all liability for any events, damages, or claims arising from the use of our Services by individuals under the age of 16. If we discover that we have unintentionally collected data from a child, we will promptly delete it.
      </Paragraph>

      <SectionTitle>9. Changes to This Policy</SectionTitle>
      <Paragraph>
        We may update this Privacy Policy periodically. If significant changes are made, we will notify you by email or through in-app notifications before the changes take effect. Please review the policy regularly for updates.
      </Paragraph>

      <Divider />
      <SectionTitle>Contact Us</SectionTitle>
      <Paragraph>
        If you have any questions or concerns about this Privacy Policy, please contact us at: <Link href="mailto:reach@travellocal.app">reach@travellocal.app</Link>.
      </Paragraph>
    </Container>
  );
};

export default PrivacyPolicy;