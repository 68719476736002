import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha'; // Import reCAPTCHA v2

const GlobalStyle = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
`;

const AboutContainer = styled.div`
  font-family: 'Inter', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  color: #2d3436;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #0984e3;
  margin-bottom: 2rem;
  position: relative;
  text-align: center;
  
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
    width: 60px;
    height: 3px;
    background: #74b9ff;
  }
`;

const ContactGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
`;

const ContactCard = styled.div`
  padding: 2rem;
  border-radius: 16px;
  background: white;
  transition: transform 0.3s ease;
  border: 1px solid #e9ecef;
  text-align: center;
  
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(116, 185, 255, 0.1);
  }
`;

const ContactIcon = styled.div`
  font-size: 2.5rem;
  color: #0984e3;
  margin-bottom: 1rem;
`;

const ContactForm = styled.form`
  background: #f8f9fa;
  padding: 3rem;
  border-radius: 16px;
  margin: 4rem 0;
`;

const FormGroup = styled.div`
  margin-bottom: 1.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  transition: border-color 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #74b9ff;
  }
`;

const TextArea = styled.textarea`
  width: 100%;
  padding: 1rem;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  font-size: 1rem;
  min-height: 150px;
  resize: vertical;
  transition: border-color 0.3s ease;
  
  &:focus {
    outline: none;
    border-color: #74b9ff;
  }
`;

const SubmitButton = styled.button`
  background: ${({ disabled }) => (disabled ? '#ccc' : '#ff7675')};
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  width: 100%;
  
  &:hover {
    background: ${({ disabled }) => (disabled ? '#ccc' : '#ff5252')};
    transform: ${({ disabled }) => (disabled ? 'none' : 'translateY(-2px)')};
  }
`;

const SuccessMessage = styled.div`
  background: #d1fae5;
  color: #065f46;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
`;

const ErrorMessage = styled.div`
  background: #ffe3e3;
  color: #ff5252;
  padding: 1rem;
  border-radius: 8px;
  margin-top: 1rem;
  text-align: center;
`;

const LoadingSpinner = styled.div`
  border: 4px solid #f3f3f3;
  border-top: 4px solid #0984e3;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin: 0 auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const formRef = useRef();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!recaptchaToken) {
      setError('Please complete the reCAPTCHA.');
      return;
    }

    setLoading(true);
    setError('');

    try {
      await emailjs.send(
        'service_kub91gq', // Replace with your EmailJS Service ID
        'template_9vhhjxy', // Replace with your EmailJS Template ID
        {
          name: formData.name,
          email: formData.email,
          message: formData.message,
          'g-recaptcha-response': recaptchaToken,
        },
        '6EDEmxvg0-aeI8R5d' // Replace with your EmailJS User ID
      );

      setSubmitted(true);
      setFormData({ name: '', email: '', message: '' });
    } catch (err) {
      setError('Failed to send message. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
  };

  return (
    <GlobalStyle>
      <AboutContainer>
        <SectionTitle>Contact Us</SectionTitle>
        
        <ContactGrid>
          <ContactCard>
            <ContactIcon><FiMail /></ContactIcon>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Email</h3>
            <p style={{ color: '#636e72' }}>support@travellocal.com</p>
            <p style={{ color: '#636e72' }}>Response time: 24 hours</p>
          </ContactCard>

          <ContactCard>
            <ContactIcon><FiPhone /></ContactIcon>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Phone</h3>
            <p style={{ color: '#636e72' }}>+1 (800) 555-1234</p>
            <p style={{ color: '#636e72' }}>Mon-Fri, 9am-5pm PST</p>
          </ContactCard>

          <ContactCard>
            <ContactIcon><FiMapPin /></ContactIcon>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Headquarters</h3>
            <p style={{ color: '#636e72' }}>123 Travel Street</p>
            <p style={{ color: '#636e72' }}>San Francisco, CA 94107</p>
          </ContactCard>
        </ContactGrid>

        <ContactForm ref={formRef} onSubmit={handleSubmit}>
          <SectionTitle>Send Us a Message</SectionTitle>
          
          <FormGroup>
            <Input
              type="text"
              name="name"
              placeholder="Your Name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <Input
              type="email"
              name="email"
              placeholder="Your Email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </FormGroup>
          
          <FormGroup>
            <TextArea
              name="message"
              placeholder="Your Message"
              value={formData.message}
              onChange={handleChange}
              required
            />
          </FormGroup>

          <ReCAPTCHA
            sitekey="6LfZttsqAAAAAIw8VXRZ5amAB-UFo5pkdU59P2JG" // Use your reCAPTCHA v2 site key here
            onChange={onRecaptchaChange} // Captures the token from reCAPTCHA
          />
          
          <SubmitButton type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : 'Send Message'}
          </SubmitButton>
          
          {submitted && (
            <SuccessMessage>
              Thank you for your message! We'll get back to you soon.
            </SuccessMessage>
          )}

          {error && <ErrorMessage>{error}</ErrorMessage>}
        </ContactForm>
      </AboutContainer>
    </GlobalStyle>
  );
};

export default ContactPage;