import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

// Import local images
import img1 from './tripImages/1.png';
import img2 from './tripImages/2.png';
import img3 from './tripImages/3.png';
// import img4 from './tripImages/4.png';
import img5 from './tripImages/5.png';

const CurrentTripsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
  max-width: 900px;
  margin: auto;
`;

const TripCard = styled.div`
  background-color: #fff;
  padding: 40px 30px;
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: flex-end;
  min-height: 200px;
  background-image: url(${(props) => props.imageUrl});
  background-size: cover;
  background-position: center;
  position: relative;
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
`;

const TripText = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  max-width: 60%;
  color: white;
`;

const TripTitle = styled.h3`
  font-family: 'Inter', sans-serif;
  font-size: 25px;
  font-weight: 600;
  margin: 0;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  color: black;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;

const TripDates = styled.span`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  color: black;
  background-color: white;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
`;

const OptionsMenu = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: white;
  border: 1px solid lightgray;
  padding: 3px;
  border-radius: 5px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: #f3f3f3;
  }
`;

const Dot = styled.span`
  width: 3px;
  height: 3px;
  background-color: gray;
  border-radius: 50%;
  display: inline-block;
  margin: 0 1px;
`;

const ImageSelector = styled.div`
  position: absolute;
  top: 60px;
  left: 10px;
  background-color: white;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  display: ${(props) => (props.show ? 'block' : 'none')};
`;

const ChangeImageButton = styled.button`
  font-family: 'Inter', sans-serif;
  background-color: #ffffff;
  border: 1px solid #ccc;
  color: #333;
  padding: 6px 12px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
  width: auto;
  text-align: center;
  outline: none;

  &:hover {
    background-color: #f3f3f3;
    border-color: #999;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  }

  &:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5);
  }

  &:active {
    background-color: #e6e6e6;
    border-color: #888;
  }
`;

const CurrentTrips = () => {
  const [imageUrls, setImageUrls] = useState({});
  const [showImageSelector, setShowImageSelector] = useState(false);
  const [selectedTrip, setSelectedTrip] = useState(null);
  const [tripNames] = useState([
    'Paris', 'San Francisco', 'Tokyo',
  ]);

  const imageSelectorRef = useRef(null);

  const fallbackImages = [img1, img2, img3, img5];

  const fetchImage = async (tripName) => {
    let imageUrl = null;
    const maxRetries = 1;

    for (let i = 0; i < maxRetries; i++) {
      try {
        const response = await fetch(`https://api.unsplash.com/photos/random?query=${tripName}&count=1`, {
          headers: {
            Authorization: `Client-ID Nar9H9rn_TYUKY3v7q0pylJ6BSI6dCskA5A6ILC6mgE`,
          },
        });

        if (!response.ok) {
          console.error(`Failed to fetch image for ${tripName} (attempt ${i + 1}/${maxRetries}):`, response.status, response.statusText);
          continue;
        }

        const data = await response.json();

        if (data && data.length > 0) {
          const imageData = data[0];
          imageUrl = imageData.urls.regular;

          if (imageUrl) {
            break;
          } else {
            console.error(`No valid image URL found for ${tripName}. Retrying...`);
          }
        } else {
          console.error(`No valid image data found for ${tripName}. Retrying...`);
        }
      } catch (error) {
        console.error(`Error fetching image for ${tripName} (attempt ${i + 1}/${maxRetries}):`, error);
      }
    }

    // If no image found, use a random image from the local folder
    if (!imageUrl) {
      const randomImage = fallbackImages[Math.floor(Math.random() * fallbackImages.length)];
      imageUrl = randomImage; // Local image fallback
      console.warn(`Using fallback image for ${tripName}: ${imageUrl}`);
    }

    setImageUrls((prevImages) => ({
      ...prevImages,
      [tripName]: imageUrl,
    }));
  };

  const changeImage = async () => {
    if (selectedTrip) {
      await fetchImage(selectedTrip);
      setShowImageSelector(false); // Hide the image selector after changing the image
    }
  };

  const toggleImageSelector = (tripName) => {
    if (selectedTrip === tripName) {
      setShowImageSelector((prev) => !prev);
    } else {
      setSelectedTrip(tripName);
      setShowImageSelector(true);
    }
  };

  useEffect(() => {
    tripNames.forEach((tripName) => {
      fetchImage(tripName);
    });

    const handleClickOutside = (e) => {
      if (imageSelectorRef.current && !imageSelectorRef.current.contains(e.target)) {
        setShowImageSelector(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CurrentTripsContainer>
      {tripNames.map((tripName) => (
        <TripCard key={tripName} imageUrl={imageUrls[tripName]}>
          <TripText>
            <TripTitle>{tripName}</TripTitle>
            <TripDates>Some Date</TripDates>
          </TripText>
          <OptionsMenu
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from propagating to document
              toggleImageSelector(tripName);
            }}
          >
            <Dot /> <Dot /> <Dot />
          </OptionsMenu>
          {selectedTrip === tripName && (
            <ImageSelector ref={imageSelectorRef} show={showImageSelector}>
              <ChangeImageButton onClick={changeImage}>Change Image</ChangeImageButton>
            </ImageSelector>
          )}
        </TripCard>
      ))}
    </CurrentTripsContainer>
  );
};

export default CurrentTrips;
