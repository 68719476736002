import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { RecaptchaVerifier, signInWithPhoneNumber, onAuthStateChanged } from 'firebase/auth';
import { setDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../firebaseConfig'; // Adjust import path
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import PhoneInput from 'react-phone-number-input';
import { useNavigate } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

const PhoneCheckContainer = styled.div`
  max-width: 400px;
  width: 100%;
  padding: 2.5rem;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.05);
  font-family: 'Inter', sans-serif;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  border: 1px solid #f0f0f0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-sizing: border-box;
    text-align: center;
`;

const GlobalStyles = createGlobalStyle`
  .grecaptcha-badge {
    bottom: -80px !important;
    justify-content: center;
    align-items: center;
    right: 60px !important;
    transform: scale(0.8);
    transition: opacity 0.3s ease-in-out;
  }

  .grecaptcha-badge:hover {
  }
`;

const PhoneInputWrapper = styled.div`
  width: 100%;

  .PhoneInput {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 1rem;
    border: 1px solid #e8e8e8;
    border-radius: 12px;
    background: #fafafa;
    transition: all 0.2s ease;
    box-sizing: border-box;
    height: 56px; // Set a fixed height for the input container

    &:focus-within {
      border-color: #d0d0d0;
      background: #fff;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.02);
    }
  }

  .PhoneInputInput {
    flex: 1;
    border: none;
    outline: none;
    font-size: 15px;
    background: transparent;
    color: #333;
    width: 100%;
    padding: 0; // Remove default padding to align text properly

    &::placeholder {
      color: #a0a0a0;
    }
  }

  .PhoneInputCountry {
    display: flex;
    align-items: center; // Center the flag vertically
    gap: 8px;
    position: relative;
    margin-top: 8px; // Adjust this value to fine-tune the vertical alignment
  }

  .PhoneInputCountryIcon {
    width: 24px;
    height: 24px;
    border-radius: 4px;
    object-fit: cover; // Ensure the flag image fits properly
  }

  .PhoneInputCountrySelect {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
    z-index: 1;
  }

  .PhoneInputCountrySelectArrow {
    display: none;
  }

  /* Hide the country code text */
  .PhoneInputCountrySelect + .PhoneInputCountryIcon + span {
    display: none;
  }
`;

const StyledPhoneInput = styled.input`
  width: 100%;
  padding: 1rem 1.5rem;
  border: 1px solid #e8e8e8;
  border-radius: 12px;
  font-size: 15px;
  transition: all 0.2s ease;
  background: #fafafa;
  color: #333;

  &:focus {
    outline: none;
    border-color: #d0d0d0;
    background: #fff;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.02);
  }

  &::placeholder {
    color: #a0a0a0;
    font-weight: 400;
  }
`;

const ActionButton = styled.button`
  width: 100%; // Ensure the button takes the full width of its container
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background: #000;
  color: white;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  box-sizing: border-box; // Ensure padding is included in the width

  &:hover {
    background: #333;
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    background: #e8e8e8;
    color: #a0a0a0;
    transform: none;
    cursor: not-allowed;
  }
`;

const ErrorMessage = styled.div`
  color: #dc2626;
  background: #fee2e2;
  padding: 1rem;
  border-radius: 12px;
  font-size: 14px;
  border: 1px solid #fecaca;
  margin-top: 0.5rem;
`;

// const Timer = styled.div`
//   color: #888;
//   font-size: 13px;
//   text-align: center;
//   margin-top: -0.5rem;
//   font-weight: 500;
// `;

const Divider = styled.div`
  height: 1px;
  background: #eee;
  margin: 1rem 0;
`;

const CodeInputGroup = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
  margin-top: 1rem;
`;

// const RecaptchaNote = styled.div`
//   color: #888;
//   font-size: 11px;
//   text-align: center;
//   margin-top: 1.5rem;
//   line-height: 1.4;
// `;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
`;

const ModalContent = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

const CloseButton = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const TextLink = styled.p`
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const SupportLink = styled.a`
 font-family: 'Inter', sans-serif; /* Set font to Inter */
  font-weight: 600; /* Make the text bold */
  color: black; /* Set default color to black */

  &:visited {
    color: black; /* Ensure the color stays black after being clicked */
  }
`;

const PhoneCheck = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [code, setCode] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [timer, setTimer] = useState(60);
  const [isTimerActive, setIsTimerActive] = useState(false);
  const [isVerified, setIsVerified] = useState(false);
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);  // State to track modal visibility

  const recaptchaVerifierRef = useRef(null);
  const recaptchaWidgetIdRef = useRef(null);

  useEffect(() => {
    // Initialize reCAPTCHA verifier
    const initializeRecaptcha = async () => {
      try {
        recaptchaVerifierRef.current = new RecaptchaVerifier(auth, 'recaptcha-container', {
          size: 'invisible',
          'expired-callback': () => {
            setError('Session expired. Please try again.');
            resetRecaptcha();
          },
          'error-callback': () => {
            setError('reCAPTCHA verification failed.');
            resetRecaptcha();
          }
        });

        // Store widget ID
        const widgetId = await recaptchaVerifierRef.current.render();
        recaptchaWidgetIdRef.current = widgetId;
      } catch (err) {
        console.error('reCAPTCHA initialization error:', err);
        setError('Failed to initialize security check. Please reload the page.');
      }
    };

    const resetRecaptcha = () => {
      if (recaptchaWidgetIdRef.current !== null) {
        window.grecaptcha.reset(recaptchaWidgetIdRef.current);
      }
    };

    initializeRecaptcha();

    // Auth state listener
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        console.log('User is signed in:', user.uid);
      } else {
        console.log('User is signed out');
      }
    });

    return () => {
      unsubscribe();
      if (recaptchaVerifierRef.current) {
        recaptchaVerifierRef.current.clear();
      }
    };
  }, []);

  useEffect(() => {
    if (isTimerActive && timer > 0) {
      const interval = setInterval(() => setTimer((prev) => prev - 1), 1000);
      return () => clearInterval(interval);
    } else if (timer === 0) {
      setIsTimerActive(false);
    }
  }, [isTimerActive, timer]);


  const handleSendCode = async () => {
    try {
      setError('');
      const user = auth.currentUser;
      if (!user) throw new Error('User not authenticated');

      // Validate phone number
      const parsedNumber = parsePhoneNumberFromString(phoneNumber);
      if (!parsedNumber?.isValid()) throw new Error('Invalid phone number');
      const formattedPhone = parsedNumber.format('E.164');

      // Check existing users
      const usersRef = collection(db, 'users');
      const q = query(usersRef, where('phoneNumber', '==', formattedPhone));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.docs.some(doc => doc.id !== user.uid)) {
        throw new Error('This number is already registered');
      }

      // Verify reCAPTCHA setup
      if (!recaptchaVerifierRef.current) {
        throw new Error('Security check failed. Please reload the page.');
      }

      // Send verification code
      const result = await signInWithPhoneNumber(
        auth,
        formattedPhone,
        recaptchaVerifierRef.current
      );
      
      setConfirmationResult(result);
      setCodeSent(true);
      setIsTimerActive(true);
      setTimer(60);
    } catch (err) {
      setError(err.message);
      console.error('Error sending code:', err);
      if (recaptchaWidgetIdRef.current !== null) {
        window.grecaptcha.reset(recaptchaWidgetIdRef.current);
      }
    }
  };

  const handleResendCode = async () => {
    await handleSendCode(); // Reuse the same logic
  };

const handleVerifyCode = async () => {
  try {
    setError('');
    const user = auth.currentUser;
    if (!confirmationResult || !user) {
      throw new Error('User not authenticated or confirmation result missing.');
    }

    // Confirm the verification code
    await confirmationResult.confirm(code);

    // Parse and format the phone number in E.164 format
    const parsedNumber = parsePhoneNumberFromString(phoneNumber);
    if (!parsedNumber || !parsedNumber.isValid()) {
      throw new Error('Invalid phone number');
    }
    const formattedPhone = parsedNumber.format('E.164'); // Ensure E.164 format
    console.log('Formatted phone number:', formattedPhone);

    // Update or create Firestore document with verification timestamp
    await setDoc(
      doc(db, 'users', user.uid),
      {
        phoneNumber: formattedPhone, // Use the formatted phone number
        phoneVerified: true,
        phoneVerifiedAt: new Date().toISOString(),
      },
      { merge: true } // Merge with existing document if it exists
    );

    console.log('Phone number verified and saved!');
    setIsVerified(true); // Set verification success
    setTimeout(() => navigate('/setup'), 2000); // Redirect after 2 seconds
  } catch (err) {
    setError(err.message);
    console.error('Error verifying code:', err);

    // Log Firestore permission errors
    if (err.code === 'permission-denied') {
      console.error('Firestore permission denied. Check security rules.');
    }
  }
};

const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

return (
    <>
     <GlobalStyles/>
        <PhoneCheckContainer>
          {isVerified ? (
            <div style={{ textAlign: 'center', color: '#333' }}>
              <h2 style={{ marginBottom: '1rem' }}>✅ Phone Verified!</h2>
              <p>Redirecting to setup...</p>
            </div>
          ) : (
            <>
              <div id="recaptcha-container" />
              <h2 style={{ 
                margin: '0 0 1rem 0', 
                fontSize: '1.5rem', 
                fontWeight: '600', 
                textAlign: 'center',
                color: '#222'
              }}>
                {!codeSent ? 'Verify Phone Number' : 'Enter Verification Code'}
              </h2>
      
              {error && <ErrorMessage>{error}</ErrorMessage>}
      
              {!codeSent ? (
                <>
                  <PhoneInputWrapper>
                    <PhoneInput
                      international
                      defaultCountry="US"
                      placeholder="Enter phone number"
                      value={phoneNumber}
                      onChange={setPhoneNumber}
                    />
                  </PhoneInputWrapper>
                  <ActionButton onClick={handleSendCode}>
                    Send Verification Code
                  </ActionButton>
                </>
              ) : (
                <CodeInputGroup>
                  <StyledPhoneInput
                    type="text"
                    placeholder="123456"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                  />
                  
                  <ActionButton onClick={handleVerifyCode}>
                    Confirm Code
                  </ActionButton>
                  
                  <Divider />
                  
                  <ActionButton
                    onClick={handleResendCode}
                    disabled={isTimerActive}
                    style={{ background: 'transparent', color: '#666', border: '1px solid #eee' }}
                  >
                    Resend Code {isTimerActive && `(Wait ${timer}s)`}
                  </ActionButton>
                </CodeInputGroup>
              )}
            </>
          )}
          <TextLink onClick={toggleModal}>Why is this required?</TextLink>
        </PhoneCheckContainer>
        {isModalOpen && (
        <ModalOverlay onClick={toggleModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalTitle>Why is a phone number required?</ModalTitle>
            <ModalContent>
                We require your phone number to ensure your safety, verify your identity, and prevent spam or misuse of the application.
                Each account must be linked to a unique phone number; duplicate accounts using the same number are not allowed.
              <br/><br/>
              There is no other way to verify your account. Contact <SupportLink href="/support">Support</SupportLink> if you have any questions
            </ModalContent>
            <CloseButton onClick={toggleModal}>Close</CloseButton>
          </ModalContainer>
        </ModalOverlay>
      )}
        </>
        
      );
};

export default PhoneCheck;