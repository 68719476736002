// import React from 'react';
// import styled from 'styled-components';
// import { useNavigate } from 'react-router-dom';
// import { FaLinkedin, FaInstagram, FaTwitter, FaTiktok } from 'react-icons/fa';
// import NimaiGarg from '../../images/NimaiGarg.png';

// const GlobalStyle = styled.div`
//   @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
// `;

// const AboutContainer = styled.div`
//   font-family: 'Inter', sans-serif;
//   max-width: 1200px;
//   margin: 0 auto;
//   padding: 4rem 2rem;
//   color: #2d3436;
// `;

// const SectionTitle = styled.h2`
//   font-size: 2.5rem;
//   color: #0984e3;
//   margin-bottom: 2rem;
//   position: relative;
//   text-align: center;
  
//   &::after {
//     content: '';
//     position: absolute;
//     bottom: -10px;
//     left: 50%;
//     transform: translateX(-50%);
//     width: 60px;
//     height: 3px;
//     background: #74b9ff;
//   }
// `;

// const TeamGrid = styled.div`
//   display: grid;
//   grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
//   gap: 2rem;
//   margin: 3rem 0;
// `;

// const LeaderCard = styled.div`
//   padding: 1.5rem;
//   border-radius: 16px;
//   background: white;
//   transition: transform 0.3s ease;
//   border: 1px solid #e9ecef;
//   text-align: center;
  
//   &:hover {
//     transform: translateY(-5px);
//     box-shadow: 0 10px 20px rgba(116, 185, 255, 0.1);
//   }
// `;

// const LeaderImage = styled.img`
//   width: 100%;
//   max-width: 240px;
//   height: auto;
//   border-radius: 12px;
//   margin: 0 auto 1.5rem;
//   object-fit: cover;
// `;

// const LeaderName = styled.h3`
//   font-size: 1.5rem;
//   color: #0984e3;
//   margin-bottom: 0.5rem;
// `;

// const LeaderTitle = styled.p`
//   color: #636e72;
//   font-weight: 500;
//   margin-bottom: 1rem;
// `;

// const LeaderDescription = styled.p`
//   color: #636e72;
//   line-height: 1.6;
//   margin-bottom: 1.5rem;
// `;

// const Button = styled.button`
//   background: #ff7675;
//   color: white;
//   border: none;
//   padding: 0.75rem 1.5rem;
//   border-radius: 8px;
//   font-weight: 600;
//   cursor: pointer;
//   transition: all 0.3s ease;
//   margin-bottom: 1.5rem;
  
//   &:hover {
//     background: #ff5252;
//     transform: translateY(-2px);
//   }
// `;

// const SocialLinks = styled.div`
//   display: flex;
//   justify-content: center;
//   gap: 1rem;
//   margin-top: 1rem;

//   a {
//     color: #0984e3;
//     font-size: 1.25rem;
//     transition: color 0.3s ease;
    
//     &:hover {
//       color: #74b9ff;
//     }
//   }
// `;

// const LeadershipPage = () => {
//   const navigate = useNavigate();

//   const leaders = [
//     {
//       name: 'Michelle Dong',
//       title: 'Chief Marketing Officer',
//       description: 'Marketing strategist bringing TravelLocal to the forefront of the travel industry.',
//       image: 'path_to_alex_image.jpg',
//       link: '/about-us/leadership/alex-patel',
//     },
//     {
//       name: 'Nimai Garg',
//       title: 'Founder, CEO, Developer',
//       description: 'Visionary leader dedicated to redefining how travelers connect worldwide.',
//       image: NimaiGarg,
//       link: '/company/about-us/leadership/nimai-garg',
//     },
//     {
//       name: 'Bhoomika Pullela',
//       title: 'Chief Operations Officer',
//       description: 'Operational genius ensuring TravelLocal runs smoothly worldwide.',
//       image: 'path_to_samantha_image.jpg',
//       link: '/about-us/leadership/samantha-lee',
//     },
//     {
//       name: 'Ryan Tseng',
//       title: 'Chief Finance Officer',
//       description: 'Financial expert ensuring sustainable growth and fiscal success.',
//       image: 'path_to_raj_image.jpg',
//       link: '/about-us/leadership/raj-mehta',
//     },
//     {
//       name: 'Charlie Watters',
//       title: 'Chief Technology Officer',
//       description: 'Tech innovator ensuring seamless user experiences for global travelers.',
//       image: 'path_to_jessica_image.jpg',
//       link: '/about-us/leadership/jessica-brown',
//     },
//   ];

//   return (
//     <GlobalStyle>
//       <AboutContainer>
//         <SectionTitle>Our Leadership Team</SectionTitle>
        
//         <TeamGrid>
//           {leaders.map((leader, index) => (
//             <LeaderCard key={index}>
//               <LeaderImage src={leader.image} alt={leader.name} />
//               <LeaderName>{leader.name}</LeaderName>
//               <LeaderTitle>{leader.title}</LeaderTitle>
//               <LeaderDescription>{leader.description}</LeaderDescription>
//               <Button
//                 onClick={() =>
//                   leader.name === 'Nimai Garg' 
//                     ? navigate(leader.link) 
//                     : (window.location.href = leader.link)
//                 }
//               >
//                 Learn More
//               </Button>
//               <SocialLinks>
//                 <a href="#" target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
//                 <a href="#" target="_blank" rel="noopener noreferrer"><FaInstagram /></a>
//                 <a href="#" target="_blank" rel="noopener noreferrer"><FaTwitter /></a>
//                 <a href="#" target="_blank" rel="noopener noreferrer"><FaTiktok /></a>
//               </SocialLinks>
//             </LeaderCard>
//           ))}
//         </TeamGrid>
//       </AboutContainer>
//     </GlobalStyle>
//   );
// };

// export default LeadershipPage;

import React from 'react'

const Leadership = () => {
  return (
    <div>
      
    </div>
  )
}

export default Leadership
