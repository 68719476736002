import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { auth } from '../firebaseConfig'; // Import the Firebase auth instance
import { sendEmailVerification, reload } from 'firebase/auth';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: 'Inter', sans-serif;
  text-align: center;
  background-color: #f9f9f9;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 1.75rem;
  margin-bottom: 1rem;
  color: #333;
`;

const Message = styled.p`
  font-size: 1rem;
  margin-bottom: 1.5rem;
  color: #555;
  max-width: 400px;
`;

const ErrorMessage = styled.p`
  color: #d32f2f;
  font-size: 0.875rem;
  margin-bottom: 1rem;
`;

const Button = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: 10px 24px;
  font-size: 1rem;
  cursor: pointer;
  margin: 0.5rem;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #222;
  }

  &:disabled {
    background-color: #bbb;
    cursor: not-allowed;
  }
`;

const EmailCheck = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [isResending, setIsResending] = useState(false); // To handle resend cooldown

  const handleContinue = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        await reload(user);

        if (user.emailVerified) {
          navigate('/phone-check');
        } else {
          setErrorMessage('Please verify your email before continuing.');
        }
      } catch (error) {
        setErrorMessage(`Error: ${error.message}`);
      }
    } else {
      setErrorMessage('No user found. Please sign in again.');
    }
  };

  const handleResendVerification = async () => {
    const user = auth.currentUser;

    if (user) {
      try {
        setIsResending(true);
        await sendEmailVerification(user);
        setErrorMessage('Verification email sent. Please check your inbox.');

        setTimeout(() => {
          setIsResending(false);
        }, 60000);
      } catch (error) {
        if (error.code === 'auth/too-many-requests') {
          setErrorMessage('Too many requests. Please wait before trying again.');
        } else {
          setErrorMessage(`Error sending verification email: ${error.message}`);
        }
      }
    } else {
      setErrorMessage('No user found. Please sign in again.');
    }
  };

  return (
    <PageContainer>
      <Title>Verify Your Email</Title>
      <Message>Please check your inbox and click the verification link.</Message>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Button onClick={handleContinue}>Continue</Button>
      <Button onClick={handleResendVerification} disabled={isResending}>
        {isResending ? 'Resending...' : 'Resend Verification Email'}
      </Button>
    </PageContainer>
  );
};

export default EmailCheck;
