import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FaLinkedin, FaInstagram, FaTwitter, FaTiktok, FaArrowLeft } from 'react-icons/fa'; // Font Awesome icons
import NimaiGarg from '../../images/NimaiGarg.png'; // Adjust the path if necessary

const ProfileCard = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <BackButton onClick={() => navigate('/about-us/leadership')}>
        <FaArrowLeft size={20} /> Back
      </BackButton>
      <ProfileCardWrapper>
        <ProfileWrapper>
          <Image src={NimaiGarg} alt="Nimai Garg" />
          <TextWrapper>
            <Name>Nimai Garg</Name>
            <Title>Founder, CEO, Developer</Title>
            <SocialMediaBar>
              <SocialMediaIcon href="#" target="_blank">
                <FaLinkedin />
              </SocialMediaIcon>
              <SocialMediaIcon href="#" target="_blank">
                <FaInstagram />
              </SocialMediaIcon>
              <SocialMediaIcon href="#" target="_blank">
                <FaTwitter />
              </SocialMediaIcon>
              <SocialMediaIcon href="#" target="_blank">
                <FaTiktok />
              </SocialMediaIcon>
            </SocialMediaBar>
          </TextWrapper>
        </ProfileWrapper>
      </ProfileCardWrapper>
      <DescriptionWrapper>
        <Description>
          Nimai Garg is the Founder, CEO, and Developer of TravelMate, a platform dedicated to bringing travelers closer to each other, no matter where they are in the world. His vision is to create a community where travelers can meet fellow adventurers, locals, guides, and service providers, making the travel experience more dynamic and interconnected. 
        </Description>
        <Description>
          Before founding TravelMate, Nimai worked extensively in the tech industry, developing innovative solutions that helped improve user experiences in various sectors. His passion for connecting people through technology and his deep understanding of the travel industry's potential led him to create TravelMate, a unique platform that caters to the needs of modern travelers looking for genuine connections.
        </Description>
        <Description>
          Nimai's journey as an entrepreneur and developer has been marked by resilience, creativity, and a relentless drive to redefine the travel landscape. Under his leadership, TravelMate has already begun making waves in the industry, and Nimai continues to explore new ways to enhance the platform, making it a must-have app for travelers around the globe.
        </Description>
      </DescriptionWrapper>
    </Container>
  );
};

export default ProfileCard;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 60px 40px;
  background-color: #f9f9f9;
  font-family: 'Inter', sans-serif;
  min-height: 100vh; /* Ensures the page is at least 100% of the viewport height */
`;

const BackButton = styled.button`
  align-self: flex-start;
  background-color: #007aff;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 1.2rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  border-radius: 8px;
  transition: background-color 0.3s;

  &:hover {
    background-color: #005a99;
  }

  svg {
    margin-right: 8px;
  }
`;

const ProfileCardWrapper = styled.div`
  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 30px;
  width: 100%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 30px;
`;

const Image = styled.img`
  width: 300px; /* Increased the size of the image */
  height: auto;
  max-height: 400px; /* Added a max-height to ensure it fits well */
  border-radius: 8px;
  margin-right: 40px;
  object-fit: contain;
`;

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Name = styled.h1`
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 8px;
  color: #333;
`;

const Title = styled.h2`
  font-size: 18px;
  font-weight: 400;
  color: #666;
  margin-bottom: 24px;
`;

const SocialMediaBar = styled.div`
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 15px;
`;

const SocialMediaIcon = styled.a`
  font-size: 1.5rem;
  color: #007acc;
  transition: color 0.3s;

  &:hover {
    color: #005a99;
  }
`;

const DescriptionWrapper = styled.div`
  max-width: 800px;
  text-align: left;
  margin-top: 40px;
  padding: 0 20px;
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.4;
  color: #444;
  margin-bottom: 20px;
  text-align: justify;
`;