import React from 'react';
import styled from 'styled-components';
import { FiGlobe, FiUsers, FiShield, FiMessageCircle, FiStar } from 'react-icons/fi';

const AboutContainer = styled.div`
  font-family: 'Inter', sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  padding: 4rem 2rem;
  color: #2d3436;
`;

const SectionTitle = styled.h2`
  font-size: 2.5rem;
  color: #0984e3;
  margin-bottom: 2rem;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -10px;
    left: 0;
    width: 60px;
    height: 3px;
    background: #74b9ff;
  }
`;

const MissionSection = styled.div`
  background: #f8f9fa;
  padding: 3rem;
  border-radius: 16px;
  margin-bottom: 4rem;
  box-shadow: 0 8px 30px rgba(0,0,0,0.05);
`;

const FeatureGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin: 3rem 0;
`;

const FeatureCard = styled.div`
  padding: 2rem;
  border-radius: 12px;
  background: white;
  transition: transform 0.3s ease;
  border: 1px solid #e9ecef;
  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(116, 185, 255, 0.1);
  }
`;

const IconWrapper = styled.div`
  font-size: 2.5rem;
  color: #0984e3;
  margin-bottom: 1rem;
`;

const SafetySection = styled.div`
  background: #fff5f5;
  padding: 3rem;
  border-radius: 16px;
  margin: 4rem 0;
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const CTASection = styled.div`
  text-align: center;
  padding: 4rem 0;
  background: linear-gradient(135deg, #0984e3 0%, #74b9ff 100%);
  border-radius: 16px;
  color: white;
`;

const Button = styled.button`
  background: #ff7675;
  color: white;
  border: none;
  padding: 1rem 2rem;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    background: #ff5252;
    transform: translateY(-2px);
  }
`;

const GlobalStyle = styled.div`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
`;

export default function About() {
  return (
    <GlobalStyle>
      <AboutContainer>
        <MissionSection>
          <SectionTitle>Redefining Travel Connections</SectionTitle>
          <p style={{ fontSize: '1.2rem', lineHeight: '1.6', color: '#636e72' }}>
            At TravelLocal, we're tearing down the barriers between tourists and authentic experiences. 
            Our platform transforms ordinary trips into cultural exchanges, creating meaningful connections 
            that last long after the journey ends.
          </p>
        </MissionSection>

        <SectionTitle>Why Choose TravelLocal?</SectionTitle>
        <FeatureGrid>
          <FeatureCard>
            <IconWrapper><FiUsers /></IconWrapper>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Human-Centered Travel</h3>
            <p style={{ color: '#636e72' }}>Connect with verified locals and travelers who share your interests and travel style.</p>
          </FeatureCard>

          <FeatureCard>
            <IconWrapper><FiGlobe /></IconWrapper>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Authentic Experiences</h3>
            <p style={{ color: '#636e72' }}>Access hidden gems and local knowledge you won't find in guidebooks or tourist apps.</p>
          </FeatureCard>

          <FeatureCard>
            <IconWrapper><FiShield /></IconWrapper>
            <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>Safe Connections</h3>
            <p style={{ color: '#636e72' }}>Our verification system and community reviews ensure trustworthy interactions.</p>
          </FeatureCard>
        </FeatureGrid>

        <SafetySection>
          <div style={{ flex: 1 }}>
            <h3 style={{ fontSize: '2rem', color: '#d63031', marginBottom: '1rem' }}>Your Safety First</h3>
            <p style={{ color: '#636e72' }}>
              We employ multi-layered verification, real-time support, and community moderation 
              to create a safe space for genuine connections. Every interaction is protected with 
              end-to-end encryption.
            </p>
          </div>
          <FiShield style={{ fontSize: '4rem', color: '#d63031' }} />
        </SafetySection>

        <SectionTitle>How It Works</SectionTitle>
        <FeatureGrid>
          {[
            { icon: <FiStar />, title: 'Create Profile', text: 'Share your travel style and interests' },
            { icon: <FiMessageCircle />, title: 'Connect', text: 'Chat with locals and fellow travelers' },
            { icon: <FiGlobe />, title: 'Explore Together', text: 'Plan meetups and shared experiences' },
          ].map((feature, index) => (
            <FeatureCard key={index}>
              <IconWrapper>{feature.icon}</IconWrapper>
              <h3 style={{ color: '#0984e3', marginBottom: '1rem' }}>{feature.title}</h3>
              <p style={{ color: '#636e72' }}>{feature.text}</p>
            </FeatureCard>
          ))}
        </FeatureGrid>

        <CTASection>
          <h2 style={{ fontSize: '2.5rem', marginBottom: '1.5rem' }}>Ready to Travel Differently?</h2>
          <p style={{ fontSize: '1.2rem', marginBottom: '2rem' }}>
            Join our global community of curious travelers and passionate locals
          </p>
          <Button>Start Your Journey</Button>
        </CTASection>
      </AboutContainer>
    </GlobalStyle>
  );
}