import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { auth } from './firebaseConfig';
import Landing from './views/Landing';
import Locals from './views/Locals';
import Login from './views/Login';
import SignUp from './views/SignUp';
import ForgotPassword from './views/ForgotPassword';
import Setup from './views/Setup';
import Settings from './views/Settings';
import NotFound from './views/NotFound';

import PhoneCheck from './views/PhoneCheck';
import EmailCheck from './views/EmailCheck';

import Guide from './views/resources/Guide';
import SafetyAndTrust from './views/resources/SafetyAndTrust';
import Community from './views/resources/Community';
import Partners from './views/resources/Partners';
import FAQ from './views/resources/FAQ';

import TermsOfUse from './views/legal/TermsOfUse';
import PrivacyPolicy from './views/legal/PrivacyPolicy';

import About from './views/company/About';
import Careers from './views/company/Careers';
import Support from './views/company/Support';
import Leadership from './views/company/Leadership';
import NimaiGarg from './views/company/NimaiGarg';

import LocalsSetup from './views/locals/LocalsSetup';
import LocalsDashboard from './views/locals/LocalsDashboard';
import LocalsText from './views/locals/LocalsText';
import ActiveRequests from './views/locals/ActiveRequests';

import TravelersSetup from './views/travelers/TravelersSetup';
import TravelersDashboard from './views/travelers/TravelersDashboard';
import TravelersText from './views/travelers/TravelersText';
import CurrentTrips from './views/travelers/CurrentTrips';
import PlanTrip from './views/travelers/PlanTrip';

import TravelerTest from './views/travelers/TravelerTest';

import GlobalStyle from './GlobalStyles';

const App = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });
    return () => unsubscribe();
  }, []);
  
  return (
    <Router>
      <GlobalStyle />
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/locals" element={<Locals />} />

        <Route path="/login" element={<Login />} /> 
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/setup" element={user ? <Setup /> : <Login/>} />
        <Route path="/settings" element={<Settings/>} />

        <Route path="/phone-check" element={user ? <PhoneCheck /> : <Login/>} />
        <Route path="/email-check" element={user ? <EmailCheck /> : <Login/>} />

        <Route path="/legal/terms-of-use" element={<TermsOfUse/>} />
        <Route path="/legal/privacy-policy" element={<PrivacyPolicy/>} />

        <Route path="/company/about-us" element={<About/>} />
        <Route path="/company/careers" element={<Careers/>} />
        <Route path="/company/support" element={<Support/>} />
        <Route path="/company/about-us/leadership" element={<Leadership/>} />
        <Route path="/company/about-us/leadership/nimai-garg" element={<NimaiGarg/>} />

        <Route path="/resources/guide" element={<Guide/>} />
        <Route path="/resources/safety-and-trust" element={<SafetyAndTrust/>} />
        <Route path="/resources/community" element={<Community/>} />
        <Route path="/resources/partners" element={<Partners/>} />
        <Route path="/resources/faqs" element={<FAQ/>} />

        <Route path="/locals/setup" element={<LocalsSetup/>} />
        <Route path="/locals/dashboard" element={user ? <LocalsDashboard /> : <Login/>} />
        <Route path="/locals/text" element={<LocalsText/>} />
        <Route path="/locals/active-requests" element={user ? <ActiveRequests /> : <Login/>} />

        <Route path="/travelers/setup" element={<TravelersSetup/>} />
        <Route path="/travelers/dashboard" element={user ? <TravelersDashboard /> : <Login/>} />
        <Route path="/travelers/text" element={user ? <TravelersText /> : <Login/>} />
        <Route path="/travelers/current-trips" element={<CurrentTrips />} />
        <Route path="/travelers/plan-a-new-trip" element={<PlanTrip />} />

        <Route path="/travelers/test" element={user ? <TravelerTest /> : <Login/>} />
        
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;