import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { doc, setDoc } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig';
import '../fonts/fonts.css';
import logoImage from '../images/logo.png';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
  overflow-x: hidden;
  background-color: #fff;
  font-family: 'Inter', sans-serif;
`;

const HeaderContainer = styled.div`
  background-color: #fff;
  display: flex;
  align-items: center;
  padding: 1.5em;
  box-sizing: border-box;
`;

const Logo = styled.img`
  height: 200px;
  width: auto;
  margin-right: 10px;
  margin-top: 15px;
  cursor: pointer;

  @media (max-width: 768px) {
    height: 60px;
    margin-top: 5px;
  }

  @media (max-width: 480px) {
    height: 50px;
    margin-top: 3px;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.75rem;
   font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: black;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }

  @media (max-width: 480px) {
    font-size: 1.25rem;
  }
`;

const Card = styled.div`
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-sizing: border-box;
  margin-bottom: 2rem; /* Add space below the Card */
`;

const BigTitle = styled.h2`
  font-size: 2rem;
  font-family: 'Inter', sans-serif;
  font-weight: 700;
  color: #333;
  text-align: center;
  margin-top: -1rem; /* Move BigTitle closer to the logo */
  margin-bottom: 1rem; /* Reduce space below BigTitle */
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
`;

const Title = styled.h2`
  font-size: 1.6rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  color: #333;
  text-align: center;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ccc;
  padding-bottom: 1rem;
`;

const Button = styled.button`
  color: #fff;
  background-color: ${({ selected }) => (selected ? '#333' : '#000')};
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  border: none;
  border-radius: 10px;
  padding: 15px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }
`;

const TextLink = styled.p`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  margin-top: 10px;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background-color: #fff;
  padding: 2rem;
  border-radius: 10px;
  max-width: 500px;
  width: 80%;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
`;

const ModalTitle = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1rem;
  font-family: 'Inter', sans-serif;
  font-weight: 600;
`;

const ModalContent = styled.p`
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
`;

const CloseButton = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    background-color: #333;
  }
`;

const Setup = () => {
  const navigate = useNavigate();
  const [role, setRole] = useState(null); // Stores either "Traveler" or "Local"
  const [isModalOpen, setIsModalOpen] = useState(false); // State to track modal visibility

  // Save selected role in Firestore and navigate to the respective page
  const handleSelection = async (selectedRole) => {
    setRole(selectedRole);

    const user = auth.currentUser;
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      await setDoc(userRef, { role: selectedRole }, { merge: true });

      // Navigate to the specific route based on the role selected
      const route = selectedRole === 'Traveler' ? '/travelers/setup' : '/locals/setup';
      navigate(route);
    }
  };

  // Function to handle opening and closing the modal
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  // useEffect(() => {
  //   const user = auth.currentUser; // Use imported auth instead of getAuth()
    
  //   if (!user) {
  //     navigate('/phone-check');
  //     return;
  //   }
  
  //   const userDocRef = doc(db, 'users', user.uid);
    
  //   // Use real-time listener instead of single getDoc
  //   const unsubscribe = onSnapshot(userDocRef, (doc) => {
  //     if (doc.exists()) {
  //       const userData = doc.data();
  //       if (!userData.phoneVerified || !userData.phoneNumber) {
  //         navigate('/phone-check');
  //       }
  //     } else {
  //       navigate('/phone-check');
  //     }
  //   });
  
  //   return () => unsubscribe(); // Cleanup listener on unmount
  // }, [navigate]);

  return (
    <PageContainer>
      <HeaderContainer>
        <Header>
          <Logo src={logoImage} alt="GourmetChef Logo" />
        </Header>
      </HeaderContainer>
      <BigTitle>Welcome to Travel Local</BigTitle>
      <Card>
        <Title>Choose what best fits you</Title>
        <Button selected={role === 'Traveler'} onClick={() => handleSelection('Traveler')}>
          Traveler
        </Button>
        <Button selected={role === 'Local'} onClick={() => handleSelection('Local')}>
          Local
        </Button>
        <TextLink onClick={toggleModal}>What does this mean?</TextLink>
      </Card>

      {isModalOpen && (
        <ModalOverlay onClick={toggleModal}>
          <ModalContainer onClick={(e) => e.stopPropagation()}>
            <ModalTitle>What do these roles mean?</ModalTitle>
            <ModalContent>
              <strong>Traveler</strong>: As a traveler, you'll connect with locals to get personalized advice for your destination, share experiences, and discover hidden gems.<br/>
              <br></br>
              <strong>Local</strong>: As a local, you'll offer advice to travelers, share your knowledge, and help them make the most out of their trip.
            </ModalContent>
            <CloseButton onClick={toggleModal}>Close</CloseButton>
          </ModalContainer>
        </ModalOverlay>
      )}
    </PageContainer>
  );
}

export default Setup;